import Head from "next/head";
import LoanFormPopupV2 from "../../src/Drawer/LoanFormPopupV2";
import { MemoizedConnectDrawer } from "../../src/Drawer/connectDrawer";

import { MemoizedCityPopup } from "../../src/Popups/cityPopup/cityPopup";
import { MemoizedFooter } from "../../src/layouts/footer";
import BookVehicleDrawer from "../../src/modules/Home/Drawer/bookVehicleDrawer";
import CarouselDrawer from "../../src/modules/Home/Drawer/carouselDrawer";
import FullscreenDrawer from "../../src/modules/Home/HomePageRevamp/VehicleSlideDrawer";
import QlDrawerComponent from "../../src/modules/LeadDataCollection/DrawerComponents/LoanDrawerComponents";
import { MemoizedHome } from "../../src/pages/Home";

export default function Home({ city }) {
  return (
    <>
      <Head>
        <title>
          Turno.club - The best place to buy commercial three wheeler electric
          loading vehicle or electric auto in {city}
        </title>
        <meta
          name="description"
          content={`Want to buy commercial electric three wheeler vehicle in ${city}? Get the best price and loan / financing for your commercial electric vehicle from Turno. Book a free test drive for your EV today on turno.club.`}
        />
        <meta
          name="keywords"
          content="Turno, electric three wheeler, electric commercial vehicle price, 3 wheeler loading auto, electric goods vehicle, 3 wheeler, mahindra ev, piaggio price, etrio, osm"
        />
        <meta name="author" content="Turno" />
        <meta name="url" content={`https://www.turno.club/${city}`} />

        <meta name="robots" content="INDEX,ARCHIVE,FOLLOW" />
        <meta name="lang" content="en" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Facebook */}
        <meta property="og:url" content={`https://www.turno.club/${city}`} />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Turno.club - The best place to buy commercial three wheeler electric loading vehicle or electric auto in ${city}`}
        />
        <meta
          property="og:description"
          content="Want to buy commercial electric three wheeler vehicle? Get the best price and loan / financing for your commercial electric vehicle from Turno. Book a free test drive for your EV today on turno.club."
        />
        <meta property="og:image" content="/logoIcon.png" />
        <meta property="fb:app_id" content="5796260583761767" />
        <meta property="fb:pages" content="646488286564232" />
        {/* Twitter */}
        <meta
          name="twitter:description"
          content={`Want to buy commercial electric three wheeler vehicle in ${city}? Get the best price and loan / financing for your commercial electric vehicle from Turno. Book a free test drive for your EV today on turno.club.`}
        />
        <meta
          name="twitter:title"
          content={`Turno.club - The best place to buy commercial three wheeler electric loading vehicle or electric auto in ${city}`}
        />
        <meta name="twitter:site" content="@Turno" />
        <meta name="twitter:creator" content="@Turno" />
        <meta name="twitter:url" content={`https://www.turno.club/${city}`} />
        <meta
          name="twitter:app:id:googleplay"
          content="com.customerapp.turno.turno_customer_application"
        />
        <meta name="twitter:app:country" content="IN" />

        <link rel="canonical" href={`https://www.turno.club/${city}`} />

        <link rel="icon" href="/favicon.png" />
      </Head>
      <MemoizedHome />

      <MemoizedCityPopup />
      <BookVehicleDrawer />
      <CarouselDrawer />
      <FullscreenDrawer />

      <MemoizedConnectDrawer
        heading={"Talk to Us"}
        subHeading={
          "We help you find the best vehicle for your business with affordable financing. Connect with us now."
        }
        ctaText={"Call us"}
      />

      {/* V2_State_Test */}
      {/* <LoanFormPopup /> */}

      <LoanFormPopupV2 />
      <QlDrawerComponent />
      <MemoizedFooter />
    </>
  );
}

export const getServerSideProps = (context) => {
  const city = context.query.city;

  return {
    props: { city },
  };
};
